let warmupUtils // eslint-disable-line santa/no-module-state
let layout // eslint-disable-line santa/no-module-state
let _ // eslint-disable-line santa/no-module-state
let reactDOM // eslint-disable-line santa/no-module-state
let siteData // eslint-disable-line santa/no-module-state
let checkOldBrowsersOrNotMesh // eslint-disable-line santa/no-module-state

function init(layoutData, dependencies, isWarmup = false, logger) {
    warmupUtils = dependencies.warmupUtils
    layout = dependencies.layout
    _ = dependencies.lodash
    reactDOM = reactDOM || dependencies.reactDOM

    if (!(isWarmup && siteData)) {
        createSiteData(layoutData, isWarmup)
    }

    return {
        imageLoader: siteData.imageLoader,
        isMobileDevice: siteData.isMobileDevice(),
        runLayout: runLayout(logger),
        registerLayoutFunc
    }
}

function createPagesDataForWarmup(layoutData) {
    if (layoutData.isMobileView) {
        return _.mapValues(layoutData.displayedPagesData, ({data, structure}) =>
            ({data, structure: {DESKTOP: structure.DESKTOP, MOBILE: structure.DESKTOP}}))
    }
    return layoutData.displayedPagesData
}

function createSiteData(layoutData, isWarmup) {
    const {SiteData} = warmupUtils
    const siteModel = _.assign({}, _.pick(layoutData, ['publicModel', 'rendererModel', 'serviceTopology', 'requestModel', 'currentUrl', 'ssr']))
    const pagesData = isWarmup ? createPagesDataForWarmup(layoutData) : layoutData.displayedPagesData
    siteData = new SiteData(_.assign({}, siteModel, {pagesData}))
    delete siteData.resolvedDataMaps
    siteData.anchorsMap = layoutData.anchorsMap
    siteData.setRootNavigationInfo(layoutData.primaryPageNavigationInfo, true)
    siteData.pagesData = pagesData
    siteData.pagesDataRaw = {pagesData: siteData.pagesData}
    siteData.isMobileView = () => layoutData.isMobileView
    siteData.tpasRenderedInSsr = {}
    siteData.getRequestedLayoutMechanism = () => _.get(layoutData.currentUrl, ['query', 'layoutMechanism'])
    siteData.getSiteMemberDetails = () => layoutData.siteMemberDetails
}

function createLayoutApi(allRenderedRootIds) {
    const {layoutAPI} = warmupUtils

    return layoutAPI({
        getSiteData() {
            return siteData
        },
        getAllRenderedRootIds() {
            return allRenderedRootIds
        },
        isSiteBusyIncludingTransition() {
            return false
        }
    })
}

function getReactRef(component, childRefName) {
    const componentReactRef = _.get(component, ['refs', childRefName])
    return componentReactRef
}

function postWarmupGetDomNode(compRefs) {
    return function (a, b, c, d, e, f) {
        if (a && !b) {
            const node = window.document.getElementById(a)
            if (node) {
                return node
            }
        }
        let ref = _.get(compRefs, a)
        ref = ref && b ? getReactRef(ref, b) : ref
        ref = ref && c ? getReactRef(ref, c) : ref
        ref = ref && d ? getReactRef(ref, d) : ref
        ref = ref && e ? getReactRef(ref, e) : ref
        ref = ref && f ? getReactRef(ref, f) : ref
        return ref && reactDOM.findDOMNode(ref) //eslint-disable-line react/no-find-dom-node
    }
}

function warmupGetDomNode(...args) {
    const domId = args.join('')
    let node = window.document.getElementById(domId)
    if (!node) {
        const compId = args[0]
        const fix = subId => subId.replace(compId, '')
        node = window.document.getElementById(`${compId}${_(args).tail().map(fix).join('')}`)
        node = node || window.document.getElementById(_(args).uniq().join(''))
        node = node || window.document.getElementById(_.last(args))
    }
    return node
}

function getStructuresDesc(layoutAPI, isPageAllowed, isWarmup, compRefs) {
    const findDomNode = isWarmup ? warmupGetDomNode : postWarmupGetDomNode(compRefs)

    const structuresDesc = _.mapValues(layoutAPI.ssr.aspectsComponentStructures, comp => ({
        structure: comp,
        getDomNodeFunc: warmupGetDomNode
    }))

    if (isPageAllowed) {
        structuresDesc.inner = warmupUtils.structuresDescription.getInner(layoutAPI, findDomNode)
        structuresDesc.outer = warmupUtils.structuresDescription.getOuter(layoutAPI, findDomNode)
        structuresDesc.siteBackground = warmupUtils.structuresDescription.getSiteBackground(findDomNode)
        structuresDesc.wixAds = warmupUtils.structuresDescription.getWixAds(layoutAPI)

        const currentPopupId = layoutAPI.getCurrentPopupId()
        if (currentPopupId) {
            structuresDesc[currentPopupId] = warmupUtils.structuresDescription.getRootDescriptor(layoutAPI, currentPopupId, findDomNode)
        }
    }

    return structuresDesc
}

function _checkOldBrowsersOrNotMesh(sd, logger) {
    if (!sd.browserFlags().cssGridSupported) {
        logger.captureError('bolt is running with a browser that does not support css grid', {level: 'info'})
    }
    // TODO: Check with mesh team if we should use this or rendererModel.siteMetaData.renderHints.isMeshReady
    // if (sd.getMasterPageLayoutSettings().mechanism !== warmupUtils.constants.LAYOUT_MECHANISMS.MESH) {
    //     logger.captureError('bolt is running layout of a layout mechanism that is not mesh')
    // }
}

function runLayout(logger) {
    return function ({currentNavigationInfo, allRenderedRootIds, isPageAllowed, compRefs} = {}, onReady = _.noop, isWarmup = false) {
        checkOldBrowsersOrNotMesh = checkOldBrowsersOrNotMesh || _.once(_checkOldBrowsersOrNotMesh)

        if (currentNavigationInfo) {
            siteData.setRootNavigationInfo(currentNavigationInfo, true)
        }

        const pageId = siteData.getRootNavigationInfo().pageId
        const renderedRootIds = isWarmup ? ['masterPage', pageId] : allRenderedRootIds
        const layoutAPI = createLayoutApi(renderedRootIds)
        const shouldRenderPage = _.get(layoutAPI, 'ssr.shouldRenderPage', true)
        // TODO make sure structureDesc has right structure
        const structuresDesc = shouldRenderPage ? getStructuresDesc(layoutAPI, isPageAllowed, isWarmup, compRefs) : {}

        if (isWarmup) {
            warmupUtils.mobileViewportFixer.fixViewportTag(layoutAPI)
            layout.reportPresetIframes(layoutAPI)
        }

        layout.updateBodyNodeStyle(layoutAPI)
        siteData.updateScreenSize()

        checkOldBrowsersOrNotMesh(siteData, logger)

        // TODO add anchorsMap to siteData
        const reLayoutResult = layout.reLayout(structuresDesc, null, null, null, layoutAPI)
        _.assign(siteData.reLayoutedCompsMap, reLayoutResult.reLayoutedCompsMap)

        if (shouldRenderPage && isWarmup && layoutAPI.getLayoutMechanism() === 'anchors') {
            _(renderedRootIds)
                .map(id => warmupGetDomNode(id))
                .compact()
                .forEach(({style}) => {
                    style.visibility = ''
                })
        }

        onReady()

        return {
            measureMap: layoutAPI.measureMap
        }
    }
}

function registerLayoutFunc(domNode, func) {
    siteData.registerLayoutFunc(domNode, func)
}

module.exports = {
    init
}
